import { initSwiftClient } from '@swiftconnect/mobile-credentials'
import React, { useEffect, useState } from 'react'
import './App.css'

// 1. Initialize the SwiftConnect SDK
// The Base URL will be provided by SwiftConnect
const swiftClient = initSwiftClient({
  baseUrl: 'https://api.qa.swiftconnect.io',
  buttonId: 'addPasses',

  onSuccess: () => console.info('Success!'),
  onUserClosed: () => console.info('Window was closed by user'),
  onNoDeviceEligible: () => console.info('User has no eligible devices'),
})

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const signIn = () => {
    // 2. Authenticate the user
    swiftClient
      .signInWithEmailPassword(email, password)
      .then(setIsSignedIn)
      .catch((err) => alert(err.message))
  }

  useEffect(() => {
    if (isSignedIn) {
      // 3. Initialize the "Add to Apple Wallet" flow
      // Make sure this is called after your button is rendered! Here, we're using a "useEffect", but you could do this differently
      swiftClient.initAddToAppleWallet()
    }
  }, [isSignedIn])

  return (
    <div>
      <h1>Web Provisioning Sample</h1>

      {!isSignedIn && (
        <div>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="you@company.com"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />

          <button onClick={signIn}>Sign In</button>
        </div>
      )}

      {isSignedIn && (
        <>
          {/**
           * 4. Add the "Add to Wallet" button
           * Make sure to give it the ID that matches
           * Make sure to use an official button from Apple's guidelines: https://developer.apple.com/wallet/add-to-apple-wallet-guidelines/
           * Do not declare an `onClick`, the Apple widget will launch based on the ID
           */}
          <input
            id={swiftClient.config.buttonId}
            type="image"
            src="./assets/US-UK_Add_to_Apple_Wallet_RGB_101421.svg"
            alt="Add to Apple Wallet"
          />
        </>
      )}
    </div>
  )
}

export default App
